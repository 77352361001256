<template>
  <div class="list-page">
    <el-button class="top-btn" size="small" type="primary" @click="handleAdd">新增消息</el-button>
    <list-filter v-model="filterForm" :uploadFilter="ok" />
    <list-tabs class="table-tab" v-model="filterForm.tab" :isLoading="loading" :tabs="tabArr" @tab-click="handleClick">
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <div class="list-btn" v-if="filterForm.tab === 'normal'">
        <el-button size="small" type="primary" @click="handleSetTop">置顶</el-button>

        <el-button size="small" type="danger" @click="handleDel">删除</el-button>
      </div>
      <div class="list-btn" v-if="filterForm.tab === 'deleted'">
        <el-button size="small" type="primary" @click="handleReduct">还原</el-button>
        <el-button size="small" type="danger" @click="handleDel">删除</el-button>
      </div>
      <el-table :data="tbody" class="thead-light" stripe style="width: 100%" @selection-change="handleSelectionChange"
        @sort-change="sortChange">
        <!-- 勾选 -->
        <el-table-column class="select-check" fixed type="selection" width="55"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="250" fixed="right">
          <template slot-scope="scope">
            <!-- 回收站显示操作 -->
            <el-button-group v-if="filterForm.tab == 'deleted'">
              <el-button type="text" size="small" @click="ReductRow(scope.row)">
                还原
              </el-button>
              <el-button type="text" size="small" @click="deleteRow(scope.row)">删除</el-button>
            </el-button-group>
            <!-- 全部页面显示操作 -->
            <el-button-group v-if="filterForm.tab == 'normal'">

              <el-button type="text" size="small" @click="setTopRow(scope.row)">
                {{ scope.row.is_top ? "取消置顶" : "置顶" }}
              </el-button>
              <el-button type="text" size="small" @click="handleEdit(scope.row)">
                编辑
              </el-button>
              <el-button type="text" size="small" @click="recallAndResendRow(scope.row)">
                {{ (scope.row.show_text === "已结束" || scope.row.send_text === "已撤回") ? "重发" : "撤回" }}
              </el-button>
              <el-button type="text" size="small" @click="deleteRow(scope.row)">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label" :min-width="th.minWidth || ''"
            :sortable="th.sortable" :key="index">
            <template slot-scope="scope">
              <!-- 复选框 -->
              <el-checkbox v-if="th.prop === 'is_show'" v-model="tbody[scope.$index][th.prop]"
                @change="changeStatus(scope.row, th.prop)"></el-checkbox>
              <!-- 排序输入框 -->
              <PopoverInputEdit v-else-if="th.prop === 'sort'" input-type="number" :validator="validator"
                v-model="scope.row[th.prop]" @confirm="changeStatus(scope.row, th.prop)">
              </PopoverInputEdit>
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
    <!-- 设置置顶对话框 -->
    <ReSendDialog @close="val => isReSendDialog = val" :id="currentId" :visible="isReSendDialog"
      @confirm="resendDialogConfirm"></ReSendDialog>
  </div>
</template>

<script>
import listFilter from "../components/CycBroadcastMessage/ListFilter.vue";
import PopoverInputEdit from "@/base/components/Popover/PopoverInputEdit";
import Pagination from "@/base/components/Default/Pagination";
// 是否置顶对话框
import ReSendDialog from "../components/CycBroadcastMessage/ReSendDialog.vue"
import {
  updateSort,
  updateShow,
  deleteBroadcast,
  getBroadcastList,
  softDeleteBroadcast,
  putBackBroadcast,
  putOnTheTopBroadcast,
  recallBroadcast,
} from "../api/cyc_broadcast_message";

export default {
  data() {
    return {
      tabArr: [
        { label: "全部", name: "normal" },
        { label: "回收站", name: "deleted" },
      ],
      loading: false, //加载
      thead: [
        { label: "ID", prop: "id", minWidth: 100 },
        { label: "标题", prop: "title", minWidth: 150 },
        {
          label: "接收对象",
          prop: "receive_text",
          minWidth: 150,
        },
        {
          label: "发送状态",
          prop: "send_text",
          minWidth: 120,
        },
        {
          label: "展示状态",
          prop: "show_text",
          minWidth: 120,
        },
        {
          label: "发送时间",
          prop: "start_time_text",
          minWidth: 160,
          sortable: true,
        },
        // { label: "排序", prop: "sort", minWidth: 100 },
        // { label: "显示", prop: "is_show", minWidth: 70 },
      ],
      //筛选对象
      filterForm: {
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        tab: "normal", //当前筛选tab
        page_size: 15, //每页数据量
      },
      //筛选缓存
      filters: {
        normal: null,
        deleted: null,
      },
      pageData: {
        page_size: 15,
      },
      // 数据列表
      tbody: [],
      selectArr: [], //勾选的数组id
      validator: {
        type: "integer",
        min: 0,
        message: "最小不能低于0的整数",
        trigger: "blur",
      },
      // 是否展示置顶对话框
      isReSendDialog: false,
      // 当前操作行的id
      currentId: null
    };
  },
  methods: {
    // 获取消息广播列表
    getBroadcastList(pageData) {
      this.tbody = [];
      this.pageData = {};
      this.loading = true;
      getBroadcastList(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          // this.filters[data.tab] = { ...this.filterForm };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //新增消息
    handleAdd() {
      this.$router.push({ name: "AddCycBroadcastMessage", params: { id: 0, type: "add" } });
    },
    //修改消息
    handleEdit(row) {
      this.$router.push({ name: "EditCycBroadcastMessage", params: { id: row.id, type: "edit" } });
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    // 批量删除
    handleDel() {
      if (this.selectArr.length) {
        if (this.filterForm.tab === "normal") {
          this.$msgbox
            .confirm("确定要将选中数据移入回收站吗？", "提示", {
              type: "info",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => { });
        } else {
          this.$msgbox
            .confirm("确定要将选中数据永久删除吗？", "提示", {
              type: "error",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => { });
        }
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 批量置顶
    handleSetTop() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要将选中消息置顶吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            // 置顶
            putOnTheTopBroadcast({ id: this.selectArr, is_top: 1 }).then(res => {
              this.$message({
                type: 'success',
                message: res.msg
              })
              // 重新刷新列表
              this.reflashList()
            })

          })
          .catch((err) => { });
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表删除
    deleteRow(row) {
      if (this.filterForm.tab === "normal") {
        this.$msgbox
          .confirm("确定要将此数据移入回收站吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.handleDelete({ id: row.id });
          })
          .catch((err) => { });
      } else {
        this.$msgbox
          .confirm("确定要将此数据永久删除吗？", "提示", {
            type: "error",
          })
          .then((res) => {
            this.handleDelete({ id: row.id });
          })
          .catch((err) => { });
      }
    },
    // 数据删除
    handleDelete(id) {
      this.loading = true;
      if (this.filterForm.tab === "normal") {
        // 删除到回收站
        softDeleteBroadcast(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.reflashList();
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        // 彻底删除
        deleteBroadcast(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.reflashList();
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 批量还原数据
    handleReduct() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要将选中数据还原吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.Reduct({ id: this.selectArr });
          })
          .catch((err) => { });
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表还原
    ReductRow(row) {
      this.$msgbox
        .confirm("确定要将此数据还原吗？", "提示", {
          type: "info",
        })
        .then((res) => {
          this.Reduct({ id: row.id });
        })
        .catch((err) => { });
    },
    // 还原数据
    Reduct(id) {
      this.loading = true;
      putBackBroadcast(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.reflashList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 点击编辑
    editRow(row) {
      this.$router.push({
        name: "AddSpirit",
        params: { id: row.id, type: "edit" },
      });
    },
    // 点击撤回或者消息重发
    recallAndResendRow(row) {
      // 当前 id
      this.currentId = row.id;

      if (row.show_text === "已结束" || row.send_text === "已撤回") {
        // 重发消息
        // 显示重发消息对话框
        this.isReSendDialog = true;
      } else {
        // 撤回消息功能
        // 请求数据
        const postData = {
          id: this.currentId,
        }
        // 撤回消息
        recallBroadcast(postData).then(res => {
          this.$message({
            type: 'success',
            message: '撤回消息成功！'
          })
          // 刷新列表
          this.reflashList()
        })
      }
    },
    // 点击置顶或者取消置顶
    setTopRow(row) {
      // 当前 id
      this.currentId = row.id;
      // 请求数据
      const postData = {
        id: this.currentId,
        is_top: 1
      }
      if (row.is_top) {
        // 取消置顶操作
        postData.is_top = 0
      } else {
        // 置顶操作
        postData.is_top = 1
      }
      // 设置消息置顶
      putOnTheTopBroadcast(postData).then(res => {
        this.$message({
          type: 'success',
          message: res.msg
        })
        // 刷新列表
        this.reflashList()
      })
    },
    // 切换tab栏
    handleClick(e) {
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name];
      } else {
        this.filterForm = {
          start_time: -1, //开始时间
          end_time: -1, //结束时间
          tab: e.name, //当前筛选tab
          page_size: this.pageData.page_size, //每页数据量
        };
      }
      this.getBroadcastList(this.filterForm);
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
          ? 0
          : 1;
      this.filterForm.order_by = column.prop;
      this.getBroadcastList(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1};

      this.getBroadcastList({ ...this.filterForm, ...pageData });
    },
    // 刷新列表
    reflashList() {
      this.getBroadcastList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 重发消息完成
    resendDialogConfirm() {
      this.$message({
        type: 'success',
        message: '重发消息成功！'
      })
      // 关闭对话框
      this.isReSendDialog = false;
      // 刷新列表
      this.reflashList()
    },
    // 修改列表相关状态
    changeStatus(e, key) {
      const data = {};
      data.id = e.id;
      if (key === "sort") {
        data[key] = e[key];
      } else {
        data[key] = e[key] ? 1 : 0;
      }
      this.loading = true;
      if (key === "is_show") {
        // 更新显示状态
        updateShow(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.reflashList();
          })
          .catch(() => {
            this.refreshList();
          });
      } else {
        // 更新排序状态
        updateSort(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.reflashList();
          })
          .catch(() => {
            this.refreshList();
          });
      }
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getBroadcastList(pageData);
    },
  },
  created() {
    if (this.$route.params.tab) {
      this.filterForm.tab = this.$route.params.tab;
    }
    this.getBroadcastList(this.filterForm); //获取列表数据
  },
  components: { listFilter, Pagination, PopoverInputEdit, ReSendDialog },
};
</script>

<style lang="scss" scoped>
</style>
