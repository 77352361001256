import api from "@/base/utils/request";




// 获取广播列表
export const getBroadcastList = data => {
  return api({
    url: "/admin/cyc/broadcastMessage/index",
    method: "post",
    data
  });
};
// 获取广播数据的详情
export const getBroadcastDetail = data => {
  return api({
    url: "/admin/cyc/broadcastMessage/detail",
    method: "post",
    data
  });
};
// 新增或者编辑广播数据
export const editBroadcastData = data => {
  return api({
    url: "/admin/cyc/broadcastMessage/save",
    method: "post",
    data
  });
};
// 置顶广播数据
export const putOnTheTopBroadcast = data => {
  return api({
    url: "/admin/cyc/broadcastMessage/isTop",
    method: "post",
    data
  });
};
// 撤回广播数据
export const recallBroadcast = data => {
  return api({
    url: "/admin/cyc/broadcastMessage/recall",
    method: "post",
    data
  });
};
// 重发广播数据
export const resendBroadcast = data => {
  return api({
    url: "/admin/cyc/broadcastMessage/resend",
    method: "post",
    data
  });
};
// 删除广播数据到回收站
export const softDeleteBroadcast = data => {
  return api({
    url: "/admin/cyc/broadcastMessage/softDelete",
    method: "post",
    data
  });
};
// 回收站还原广播数据
export const putBackBroadcast = data => {
  return api({
    url: "/admin/cyc/broadcastMessage/putBack",
    method: "post",
    data
  });
};
// 回收站彻底删除广播数据
export const deleteBroadcast = data => {
  return api({
    url: "/admin/cyc/broadcastMessage/delete",
    method: "post",
    data
  });
};

// 获取所属组织列表
export const parentToList = data => {
  return api({
    url: "/admin/cyc/Organization/parentToList",
    method: "post",
    data
  });
};
// 组织架构树状展示接口
export const getOrganizationsTree = data => {
  return api({
    url: "/admin/cyc/Organization/OrganizationsTree",
    method: "post",
    data
  });
};

// 更新排序
export const updateSort = data => {
  return api({
    url: "/admin/cyc/Spirit/updateSort",
    method: "post",
    data
  });
};

// 更新显示
export const updateShow = data => {
  return api({
    url: "/admin/cyc/Spirit/updateShow",
    method: "post",
    data
  });
};