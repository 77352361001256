<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="true">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium" class="filter-from">
      <div class="filter-item">
        <el-form-item label="标题：" prop="title">
          <el-input v-model="form.title" placeholder="请输入标题"></el-input>
        </el-form-item>
      </div>
      <!-- 广播消息列表筛选 -->
      <div class="filter-item">
        <el-form-item label="发送状态：" prop="send_status">
          <el-select size="medium" v-model="form.send_status" placeholder="请选择发送状态">
            <el-option v-for="(item, index) in stateList" :label="item.name" :value="item.type" :key="index">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="接收对象：" prop="receive_type">
          <el-select size="medium" v-model="form.receive_type" placeholder="请选择接收对象">
            <el-option v-for="(item, index) in receiveList" :label="item.name" :value="item.type" :key="index">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="展示状态：" prop="show_status">
          <el-select size="medium" v-model="form.show_status" placeholder="请选择接收对象">
            <el-option v-for="(item, index) in showList" :label="item.name" :value="item.type" :key="index">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="发送日期：" start-prop="create_start_time" :start-time.sync="form.start_time"
          end-prop="create_end_time" :end-time.sync="form.end_time" />
      </div>

    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
export default {
  model: {
    prop: "filterForm",
    event: "updateFilter",
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      // 状态列表
      stateList: [
        { name: "全部", type: -1 },
        { name: "未发送", type: 0 },
        { name: "已发送", type: 1 },
        { name: "已撤回", type: 2 },
      ],
      // 接收对象列表
      receiveList: [
        { name: "全部", type: -1 },
        { name: "组织", type: 0 },
        { name: "全部会员", type: 1 },
        { name: "非会员", type: 2 },
        { name: "全部(包括非会员)", type: 3 },
      ],
      // 展示状态列表
      showList: [
        { name: "全部", type: -1 },
        { name: "待展示", type: 0 },
        { name: "展示中", type: 1 },
        { name: "已结束", type: 2 },
      ],
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.form.start_time = 0;
      this.form.end_time = 0;
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() { },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {
        this.$emit("updateFilter", val);
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>