<template>
  <el-dialog class="dialog-vertical" title="消息重发" :visible="visible" @close="close" width="500px">
    <el-form ref="optionForm" class="small-form" size="medium" :model="form" label-position="right" label-width="85px"
      @submit.native.prevent>

      <DatePeriodPicker label="展示时间：" start-prop="create_start_time" :start-time.sync="form.start_time"
        end-prop="create_end_time" :end-time.sync="form.end_time" :showBtn="false" />

    </el-form>
    <template #footer>
      <el-button size="small" @click="close">取消</el-button>
      <el-button size="small" type="primary" @click="confirm" :loading="saveLoading">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import SingleMediaWall from "@/modules/common/components/SingleMediaWall";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";

import {
  resendBroadcast
} from "../../api/cyc_broadcast_message";
export default {
  name: "OptionCreateDialog",
  components: { SingleMediaWall, DatePeriodPicker },
  props: {
    id: String,
    visible: Boolean
  },
  data() {
    return {
      loading: false,
      saveLoading: false,
      // 表单数据
      form: {
        // 开始时间
        start_time: 0,
        // 结束时间
        end_time: 0
      }
    }
  },
  computed: {

  },
  methods: {
    validate() {
      this.$refs['optionForm'].validate()
    },
    // 关闭弹窗
    close() {
      this.$emit('close', false)
      this.$refs['optionForm'].clearValidate()
    },
    confirm() {
      this.$refs['optionForm'].validate(valid => {
        if (valid) {
          console.log(this.form)
          const postData = {
            id: this.$props.id,
            ...this.form
          }
          resendBroadcast(postData).then(res => {
            this.$emit('confirm', res)
          })
        } else {
          return false
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
